import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';
import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import BackButton from '../../hoc/BackButton';
import { seaglassURL, accountID } from '../../constants';
import { logScreenView, logEvent, handleResponseCode } from "../../utility";
import { POSTSeaglass } from '../../Global/Apis/ServerApi';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToLogin: false,
    };
  }

  // Open a new window for contact valda
  handleContactValda = () => {
    logEvent('email_contact_form');
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        "https://www.valdaenergy.com/contact#contact-form",
        "_blank",
        "location=no"
      );
    } else {
      window.open("https://www.valdaenergy.com/contact#contact-form", "_blank");
    }
  };

  handleLiveChat = () => {
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}`;

    POSTSeaglass(url)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data) {
            const firstname = response.response.data.contact.firstname;
            const lastname = response.response.data.contact.lastname;
            const email = response.response.data.contact.email;
            const mobile = response.response.data.contact.mobile;
            logEvent("start_live_chat");
            if (window.cordova) {
              const url = `https://home-e32.niceincontact.com/inContact/ChatClient/ChatClient.aspx?poc=0dec6676-8a5e-4fdc-8d43-02ad58d7288a&bu=4599065&P0=${firstname}&P2=${lastname}&P3=${email}&P4=${mobile}`;
              window.cordova.InAppBrowser.open(url, "_blank", "location=no");
              return;
            }
            window.icPatronChat.init({
              serverHost: "https://home-e32.niceincontact.com",
              bus_no: 4599065,
              poc: "0dec6676-8a5e-4fdc-8d43-02ad58d7288a",
              params: [firstname, lastname, email, mobile],
              customChatButton: true,
            });

            setTimeout(() => {
              window.icPatronChat.setStateWindowToggle();
            }, 1000);
          }
        }
      })
      .catch((error) => {});
  };

  callCustomerService = () => {
    logEvent('call_valda');
    if (window.cordova) {
      window.plugins.CallNumber.callNumber(
        () => {},
        () => {},
        "03303904510",
        true
      );
    } else {
      window.open("tel:03303904510", "_self");
    }
  };

  // Handle Back Button
  handleBackBtn = () => {
    this.setState({
      navigateToLogin: true,
    });
  };

  render() {
    logScreenView("Contact Us");
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div style={{ display: this.props.showBackBtn ? "none" : "" }}>
          <MobileSimpleTitlebar
            titleText="CONTACT US"
            showMenuBtn={this.props.showMenuBtn}
            menuClick={this.props.menuClick}
          />
        </div>
        <div className="fixedPosition">
          <div style={{ display: this.props.showBackBtn ? "" : "none" }}>
            <MobileSimpleTitlebar
              titleText="CONTACT US"
              showMenuBtn={this.props.showMenuBtn}
              menuClick={this.props.menuClick}
            />
          </div>
          <div style={{ display: this.props.showBackBtn ? "" : "none" }}>
            <BackButton btnClick={this.handleBackBtn} />
          </div>
        </div>
        <div className="desktopHalfScreenContainer">
          <div className="scrollingContainer contactUsScroller centerText">
            <div className="nintyPercentWidth" style={{ marginTop: "36px" }}>
              <div
                style={{
                  display: this.props.showBackBtn ? "" : "none",
                  height: "72px",
                }}
              />
              <AppLogo />
              <div className="marginTop boldText">Contact Details</div>
              <div className="marginTop callImage" />
              <div
                onClick={this.callCustomerService}
                onKeyPress={() => {}}
                role="link"
                className="noLinkText"
              >
                <div className="marginTop boldText">Customer Services</div>
                <div style={{ textDecoration: "underline" }}>0330 390 4510</div>
              </div>
              <div className="marginTop">09:00 - 17:00</div>
              <div>Monday to Friday.</div>
              <CustomButton
                btnName="LIVE CHAT"
                btnClick={this.handleLiveChat}
                showFullWidth
              />
              <CustomButton
                btnName="EMAIL CONTACT FORM"
                btnClick={this.handleContactValda}
                showFullWidth
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;

ContactUs.propTypes = {
  menuClick: PropTypes.func,
  showMenuBtn: PropTypes.bool,
  showBackBtn: PropTypes.bool,
};

ContactUs.defaultProps = {
  menuClick: () => false,
  showMenuBtn: true,
  showBackBtn: false,
};
